#experiencecontain {
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 100vh; 
}

#experiencecontain video {
    width: 100%;
}

.loader{
    position: "absolute"; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
}