#video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
  
  #landing-container {
    position: relative;
    width: 100%; /* full width of the parent */
    /* Use padding-top to maintain aspect ratio of 16:9 */
    padding-top: 56.25%; /* 9 / 16 * 100 */
    overflow: hidden;
  }
  
  #video-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  #overlays {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  #QRCODE {
    position: absolute;
    /* Positioning via React's inline styles */
  }

  #QRCODE img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  #QRCODE:before {
    content: '';
    display: block;
    /*padding-top: 100%;  Keep QR code aspect ratio square */
  }
  